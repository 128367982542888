<template>
  <div class="home">
    <div class="homeTop" :style="{height:getHeight}">
      <div class="homeBigImg">
        <!-- <img v-lazy="topBg" alt="" srcset="" class="bg"> -->
        <img :src="topBg" alt="" class="bg">
        <div class="text">
          <p class="qianshu">千述</p>
          <p>一站式互联网定制</p>
          <div></div>
          <p>多行业·多类型·高品质</p>
        </div>
        <div class="homeTop-right">
          <img src="../../assets/Web/home/screen.png" alt="" class="screen">
          <img src="../../assets/Web/home/base.png" alt="" class="base">
          <img src="../../assets/Web/home/shade.png" alt="" class="shade">
        </div>
      </div>
    </div>
    <div class="homeScene">
      <div class="homeScene-top">
        <img v-lazy="PXsceneTitleImage" alt="" srcset="">
        <!-- <img :src="PXsceneTitleImage" alt=""> -->
      </div>
      <div class="homeScene-bottom" @mouseleave="sceneTitleChange(' ')">
        <div class="homeScene-bottom-list">
          <div class="homeScene-bottom-list-item" v-for="(item,index) in sceneList.slice(0,4)" :key="index" @mouseenter="sceneTitleChange(item.title)">
            <div class="noCheck" v-if="item.title!=sceneTitle">
              <img :src="item.img" alt="" srcset="">
              <div class="homeScene-bottom-list-item-line"></div>
              <p>{{ item.title }}</p>
            </div>
            <div class="check" v-else>
              <img v-lazy="item.imgCheck" alt="" srcset="">
              <div class="homeScene-bottom-list-item-line"></div>
              <p>{{ item.title }}</p>
              <div class="homeScene-bottom-list-item-button" @click="goCase(item.name)">立即查看</div>
            </div>

          </div>
        </div>
        <div class="homeScene-bottom-list">
          <div class="homeScene-bottom-list-item" v-for="(item,index) in sceneList.slice(4)" :key="index" @mouseenter="sceneTitleChange(item.title)">
            <div class="noCheck" v-if="item.title!=sceneTitle">
              <img :src="item.img" alt="" srcset="" style="width:82px; height:63px;" v-if="item.title=='站点配送'">
              <img :src="item.img" alt="" srcset="" v-else>
              <div class="homeScene-bottom-list-item-line"></div>
              <p>{{ item.title }}</p>
            </div>
            <div class="check" v-else>
              <img v-lazy="item.imgCheck" alt="" srcset="" style="width:82px; height:63px;" v-if="item.title=='站点配送'">
              <img v-lazy="item.imgCheck" alt="" srcset="" v-else>
              <div class="homeScene-bottom-list-item-line"></div>
              <p>{{ item.title }}</p>
              <div class="homeScene-bottom-list-item-button" @click="goCase(item.name)">立即查看</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="homeBusiness">
      <div class="homeBusiness-top">
        <img v-lazy="businessTitleImg" alt="" srcset="">
        <!-- <img :src="businessTitleImg" alt=""> -->
      </div>
      <div class="homeBusiness-bottom">

        <div class="homeBussiness-bottom-list">
          <div class="homeBussiness-bottom-list-item1" v-for="(item,index) in businessContentList.slice(0,1)" :key="index" @mouseenter="businessTitleChange(item.title, 1)">

            <div class="noCheck" v-if="item.title!=businessContentTitle">
              <div class="homeBusiness-bottom-tab1-top">
                <img v-lazy="item.img" alt="" srcset="">
              </div>
              <div class="homeBusiness-bottom-tab1-bottom">
                <span class="homeBusiness-bottom-tab1-bottom-title">{{ item.title }}</span>
                <div class="homeBusiness-bottom-tab1-bottom-line"></div>
                <p class="homeBusiness-bottom-tab1-bottom-content" v-html="item.content"></p>
              </div>
            </div>
            <div class="check" v-else>
              <div :style="item.backgroundStyle">
                <div class="homeBusiness-bottom-check-top">
                  <img v-lazy="item.img" alt="" srcset="" :style="item.imgStyle">
                </div>
                <div class="homeBusiness-bottom-check-bottom">
                  <span class="homeBusiness-bottom-check-bottom-title">{{ item.title }}</span>
                  <div class="homeBusiness-bottom-check-bottom-line"></div>
                  <p class="homeBusiness-bottom-check-bottom-content" v-html="item.content"></p>
                </div>
              </div>
            </div>

          </div>

          <div>
            <div class="homeBussiness-bottom-list-item2" v-for="(item,index) in businessContentList.slice(1,3)" :key="index" @mouseenter="businessTitleChange(item.title, 2)">

              <div v-if="businessGroupTitle == 2">
                <div class="check" v-if="item.title==businessContentTitle">
                  <div :style="item.backgroundStyle">
                    <div class="homeBusiness-bottom-check-top">
                      <img v-lazy="item.img" alt="" srcset="" :style="item.imgStyle">
                    </div>
                    <div class="homeBusiness-bottom-check-bottom">
                      <span class="homeBusiness-bottom-check-bottom-title">{{ item.title }}</span>
                      <div class="homeBusiness-bottom-check-bottom-line"></div>
                      <p class="homeBusiness-bottom-check-bottom-content" v-html="item.content"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="noCheck" v-else>
                <div class="homeBusiness-bottom-tab1-bottom" :style="index == 1 ? 'marginTop: 8PX' : ''">
                  <span class="homeBusiness-bottom-tab1-title">{{ item.title }}</span>
                  <div class="homeBusiness-bottom-tab1-line"></div>
                  <p class="homeBusiness-bottom-tab1-content" v-html="item.content"></p>
                </div>
              </div>

            </div>
          </div>

          <div>
            <div class="homeBussiness-bottom-list-item3" v-for="(item,index) in businessContentList.slice(3,6)" :key="index" @mouseenter="businessTitleChange(item.title, 3)">

              <div v-if="businessGroupTitle == 3">
                <div class="check1" v-if="item.title==businessContentTitle">
                  <div :style="item.backgroundStyle">
                    <div class="homeBusiness-bottom-check-top">
                      <img v-lazy="item.img" alt="" srcset="" :style="item.imgStyle">
                    </div>
                    <div class="homeBusiness-bottom-check-bottom">
                      <span class="homeBusiness-bottom-check-bottom-title">{{ item.title }}</span>
                      <div class="homeBusiness-bottom-check-bottom-line"></div>
                      <p class="homeBusiness-bottom-check-bottom-content" v-html="item.content"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="noCheck" v-else>
                <div class="homeBusiness-bottom-tab3-top" :style="index >= 1 ? 'marginTop: 9PX' : ''">
                  <span class="homeBusiness-bottom-tab3-title">{{ item.title }}</span>
                  <p class="homeBusiness-bottom-tab3-content" v-html="item.content"></p>
                </div>
              </div>

            </div>
          </div>

        </div>

        <!-- <div class="homeBusiness-bottom1">
          <div class="homeBusiness-bottom-left">
            <el-carousel :interval="5000" ref="carouselRef" @change="businessImgChange" :autoplay="true" indicator-position="none">
              <el-carousel-item v-for="(item,index) in businessList" :key="index">
                <img v-lazy="item.img" alt="" srcset="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="homeBusiness-bottom-right">
            <div class="homeBusiness-bottom-right-list">
              <div @click="businessTitleClick(item.title,item.index)" :style="{backgroundColor:businessTitle==item.title?'#00407E':''}" class="homeBusiness-bottom-right-item"
                v-for="(item,index) in businessList.slice(0,2)" :key="index">
                <p v-if="item.title.length<8">{{ item.title }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(0,4) }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(4) }}</p>
              </div>
            </div>
            <div class="homeBusiness-bottom-right-list">
              <div @click="businessTitleClick(item.title,item.index)" :style="{backgroundColor:businessTitle==item.title?'#00407E':''}" class="homeBusiness-bottom-right-item"
                v-for="(item,index) in businessList.slice(2,4)" :key="index">
                <p v-if="item.title.length<8">{{ item.title }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(0,4) }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(4) }}</p>
              </div>
            </div>
            <div class="homeBusiness-bottom-right-list">
              <div @click="businessTitleClick(item.title,item.index)" :style="{backgroundColor:businessTitle==item.title?'#00407E':''}" class="homeBusiness-bottom-right-item" v-for="(item,index) in businessList.slice(4)"
                :key="index">
                <p v-if="item.title.length<8">{{ item.title }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(0,4) }}</p>
                <p v-if="item.title.length>=8">{{ item.title.slice(4) }}</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="homeService">
      <div class="homeService-top">
        <img v-lazy="serviceTitle" alt="" srcset="">
        <!-- <img :src="serviceTitle" alt=""> -->
      </div>
      <div class="homeService-bottom">
        <div class="homeService-bottom-list" v-for="(item,index) in serviceList" :key="index">
          <img :src="item.img" alt="" srcset="">
          <p>{{ item.title }}</p>
          <p>{{ item.span}}</p>
          <p>{{ item.span1 }}</p>
        </div>
      </div>
    </div>
    <div class="homeJob">
      <div class="homeJob-top">
        <img v-lazy="jobTitleImg" alt="" srcset="">
        <!-- <img :src="jobTitleImg" alt=""> -->
      </div>
      <div class="homeJob-bottom">
        <div class="homeJob-bottom-list">
          <div class="homeJob-bottom-list-itemCheck" v-if="jobTitle!='简历优化'&&jobTitle=='精准一对一'&&jobTitle!='模拟面试'">
            <div class="homeJob-bottom-list-itemCheck-top">
              <img :src="job1check" alt="" style="margin-left:24PX;width:155PX;height:129PX">
              <img :src="jobBg1" alt="" class="jobBg1" style="width:337PX;height:99PX">
              <div>
                <p>一对一沟通</p>
                <p>挖掘个人优势</p>
              </div>
            </div>
            <div class="homeJob-bottom-list-itemCheck-center">
              <p>精准一对一</p>
              <p>配备专属顾问，一对一沟通深度挖掘个人能力，</p>
              <p>了解自身优势，梳理个人核心竞争力</p>
            </div>
            <router-link :to="{name:'Job'}">
              <div class="homeJob-bottom-list-itemCheck-more" @click="navChange('求职定制')">
                <p>立即了解</p>
                <img src="../../assets/Web/home/job/right.png" alt="">
              </div>
            </router-link>
          </div>
          <div class="homeJob-bottom-list-item" :style="{borderRadius:jobTitle=='简历优化'||jobTitle=='模拟面试'?'10PX 0PX 0PX 10PX':''}" v-else @mouseenter="jobEnter('精准一对一')">
            <p>一对一沟通</p>
            <p>挖掘个人优势</p>
            <img v-lazy="job1" alt="" srcset="" style="width:101PX;height:81PX">
            <p>精准一对一</p>
            <p>配备专属顾问，一对一沟通</p>
            <p>深度挖掘个人能力，了解自</p>
            <p>身优势，梳理个人核心竞争力</p>
          </div>
          <div class="homeJob-bottom-list-itemCheck" v-if="jobTitle=='简历优化'&&jobTitle!='精准一对一'&&jobTitle!='模拟面试'">
            <div class="homeJob-bottom-list-itemCheck-top">
              <img :src="job2check" alt="" style="margin-left:29PX;width:114PX;height:130PX">
              <img :src="jobBg2" alt="" class="jobBg1" style="width:337PX;height:78PX">
              <div>
                <p>优秀简历</p>
                <p>一秒吸引HR</p>
              </div>
            </div>
            <div class="homeJob-bottom-list-itemCheck-center">
              <p>简历优化</p>
              <p>互联网资深大咖辅导并设计，扬长避短用简历语言</p>
              <p>写专业简历，大幅度提高简历通过率</p>
            </div>
            <router-link :to="{name:'Job'}">
              <div class="homeJob-bottom-list-itemCheck-more" @click="navChange('求职定制')">
                <p>立即了解</p>
                <img src="../../assets/Web/home/job/right.png" alt="">
              </div>
            </router-link>
          </div>
          <div class="homeJob-bottom-list-item" :style="{backgroundColor:jobTitle=='精准一对一'||jobTitle=='模拟面试'?'#F9F9F9':'#F3F3F3'}" v-else @mouseenter="jobEnter('简历优化')">
            <p>优秀简历</p>
            <p>一秒吸引HR</p>
            <img v-lazy="job2" alt="" srcset="" style="width:134.3PX;height:80.8PX">
            <p>简历优化</p>
            <p>互联网资深大咖辅导并设</p>
            <p>计，扬长避短用简历语言写专</p>
            <p>业简历，大幅度提高简历通过率</p>
          </div>
          <div class="homeJob-bottom-list-itemCheck" v-if="jobTitle!='简历优化'&&jobTitle!='精准一对一'&&jobTitle=='模拟面试'">
            <div class="homeJob-bottom-list-itemCheck-top">
              <img :src="job3check" alt="" style="margin-left:39PX;width:149PX;height:129PX">
              <img :src="jobBg1" alt="" class="jobBg1" style="width:337PX;height:103PX;bottom: 4PX;">
              <div>
                <p>真人演练</p>
                <p>快速提高能力</p>
              </div>
            </div>
            <div class="homeJob-bottom-list-itemCheck-center">
              <p>模拟面试</p>
              <p>提供HR仿真场景面试，解析面试常见问题，</p>
              <p>分析面试表现，提出改进建议</p>
            </div>
            <router-link :to="{name:'Job'}">
              <div class="homeJob-bottom-list-itemCheck-more" @click="navChange('求职定制')">
                <p>立即了解</p>
                <img src="../../assets/Web/home/job/right.png" alt="">
              </div>
            </router-link>
          </div>
          <div class="homeJob-bottom-list-item" :style="{borderRadius:jobTitle=='简历优化'||jobTitle=='精准一对一'?'0PX 10PX 10PX 0PX':''}" v-else @mouseenter="jobEnter('模拟面试')">
            <p>真人演练</p>
            <p>快速提高能力</p>
            <img v-lazy="job3" alt="" srcset="" style="width:123PX;height:83PX">
            <p>模拟面试</p>
            <p>提供HR防真情景面试</p>
            <p>解析面试常见问题，分析</p>
            <p>面试表现，提出改进建议</p>
          </div>
        </div>
      </div>
    </div>

    <div class="homeWhy">
      <div class="homeWhy-top">
        <img :src="whyChoose" alt="">
      </div>
      <div class="homeWhy-center">
        <img :src="whyChooseBg" alt="">
        <div class="content">
          <div class="homeWhy-center-title">为什么千述科技是您</div>
          <div class="homeWhy-center-title">最好的选择：</div>
          <p>我们是一家拥有专业开发团队，以严谨认真的态度</p>
          <p>对待每一项业务与挑战的公司。在当今纷繁的互联</p>
          <p>网环境中为每个客户提供最优质的定向服务，精准</p>
          <p>地满足客户的需求。</p>
        </div>
      </div>
    </div>

    <div class="homeSolve">
      <div class="homeSolve-content">
        <div class="homeSolve-content-top">
          <p>提交需求获取解决方案</p>
          <p>欢迎咨询，提出您的需求，客服将尽心为您解答</p>
        </div>
        <div class="homeSolve-content-center">
          <div class="homeSolve-content-center-top">
            <input type="text" placeholder="请填写您的姓名（必填）" v-model="queryParams.name">
            <input type="text" placeholder="请填写您的联系方式（必填）" v-model="queryParams.phone">
          </div>
          <textarea name="" id="" placeholder="请描述一下您的需求" v-model="queryParams.demandDescribe"></textarea>
        </div>
        <div class="homeSolve-content-bottom">
          <button @click="messageLeave">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { messageLeave } from '@/api/index.js'
// import { eventNames } from 'process'
export default {
  name: 'Home',
  components: {
  },
  props: ['navTitle', 'casePopName'],
  data() {
    return {
      // 视口高度
      // getHeight: window.innerHeight - 79 + 'px',
      getHeight: '',
      // getHeight: document.documentElement.clientHeight - 79 + 'px',

      sceneTitle: '',
      PXsceneTitleImage: require('../../assets/Web/home/scene/title.png'),
      businessTitleImg: require('../../assets/Web/home/business/title.png'),
      serviceTitle: require('../../assets/Web/home/service/title.png'),
      jobTitleImg: require('../../assets/Web/home/job/title.png'),
      whyChoose: require('../../assets/Web/home/whyChoose.png'),
      whyChooseBg: require('../../assets/Web/home/whyChooseBg.png'),
      topBg: require('../../assets/Web/home/topBg.png'),
      job1: require('../../assets/Web/home/job/1.png'),
      job1check: require('../../assets/Web/home/job/1check.png'),
      job2: require('../../assets/Web/home/job/2.png'),
      job2check: require('../../assets/Web/home/job/2check.png'),
      job3: require('../../assets/Web/home/job/3.png'),
      job3check: require('../../assets/Web/home/job/3check.png'),
      jobBg1: require('../../assets/Web/home/job/bg1.png'),
      jobBg2: require('../../assets/Web/home/job/bg2.png'),
      queryParams: {
        demandDescribe: '',
        name: '',
        phone: '',
      },
      sceneList: [
        {
          img: require('../../assets/Web/home/scene/1.png'),
          imgCheck: require('../../assets/Web/home/scene/1check.png'),
          title: 'B2C单商户商城',
          name: 'shopping',
        },
        {
          img: require('../../assets/Web/home/scene/2.png'),
          imgCheck: require('../../assets/Web/home/scene/2check.png'),
          title: '共享智能门店',
          name: '',
        },
        {
          img: require('../../assets/Web/home/scene/3.png'),
          imgCheck: require('../../assets/Web/home/scene/3check.png'),
          title: '家政上门',
          name: 'housekeeping',
        },
        {
          img: require('../../assets/Web/home/scene/4.png'),
          imgCheck: require('../../assets/Web/home/scene/4check.png'),
          title: '物业系统',
          name: 'digitization',
        },
        {
          img: require('../../assets/Web/home/scene/5.png'),
          imgCheck: require('../../assets/Web/home/scene/5check.png'),
          title: '智能门店',
          name: 'unmanned',
        },
        {
          img: require('../../assets/Web/home/scene/6.png'),
          imgCheck: require('../../assets/Web/home/scene/6check.png'),
          title: '站点配送',
          name: 'cloudTavern',
        },
        {
          img: require('../../assets/Web/home/scene/7.png'),
          imgCheck: require('../../assets/Web/home/scene/7check.png'),
          title: '预约送货',
          name: 'cakeSubscribe',
        },
        {
          img: require('../../assets/Web/home/scene/8.png'),
          imgCheck: require('../../assets/Web/home/scene/8check.png'),
          title: 'CRM系统',
          name: 'crm',
        },

      ],
      businessTitle: '千述专业开发团队',
      businessList: [
        { img: require('../../assets/Web/home/business/1.png'), title: '千述专业开发团队', index: 0 },
        { img: require('../../assets/Web/home/business/2.png'), title: 'APP开发', index: 1 },
        { img: require('../../assets/Web/home/business/3.png'), title: '智能软硬结合系统', index: 2 },
        { img: require('../../assets/Web/home/business/4.png'), title: '网站定制开发', index: 3 },
        { img: require('../../assets/Web/home/business/5.png'), title: '企业应用管理系统', index: 4 },
        { img: require('../../assets/Web/home/business/6.png'), title: '微信小程序', index: 5 },

      ],
      serviceList: [
        {
          img: require('../../assets/Web/home/service/1.png'), title: '专业开发',
          span: '拥有七年项目开发经验的',
          span1: '技术与产品团队'
        },
        {
          img: require('../../assets/Web/home/service/2.png'), title: '产品定制',
          span: '提供最优质开发方案，充分',
          span1: '满足用户需求',

        },
        {
          img: require('../../assets/Web/home/service/3.png'), title: '按时交付',
          span: '严格把控开发进度，确保',
          span1: '产品快速上线'

        },
        {
          img: require('../../assets/Web/home/service/4.png'), title: '售后保障',
          span: '保证产品上线后的跟踪运营',
          span1: '与实时维护'

        },
      ],
      jobTitle: '简历优化',

      businessContentList: [
        {
          title: '千述专业开发团队',
          img: require('../../assets/Web/home/business/team.png'),
          backgroundColor: '#6C81DA',
          isLine: true,
          content: '多年行业经验，资深产品经理一对一服务<br/>成熟技术团队，一站式服务',
          i: 1,
          backgroundStyle: { 'background': '#6C81DA' },
          imgStyle: { 'width': '178PX', 'height': '180PX', 'margin-left': '90PX', 'margin-top': '12PX' }
        },
        {
          title: 'APP开发',
          img: require('../../assets/Web/home/business/appDev.png'),
          backgroundColor: '#2ABCB0',
          isLine: true,
          content: '提供IOS、Android、HarmonyOS各<br/>系统开发',
          i: 2,
          backgroundStyle: { 'background': '#2ABCB0' },
          imgStyle: { 'width': '227PX', 'height': '175PX', 'margin-left': '66PX', 'margin-top': '14PX' }
        },
        {
          title: '智能软硬结合系统',
          img: require('../../assets/Web/home/business/soft.png'),
          backgroundColor: '#5F8ED3',
          isLine: true,
          content: '能物联系统、智慧农业、智慧24小时<br/>无人店等',
          i: 3,
          backgroundStyle: { 'background': '#5F8ED3' },
          imgStyle: { 'width': '196PX', 'height': '179PX', 'margin-left': '81PX', 'margin-top': '13PX' }
        },
        {
          title: '网站定制开发',
          img: require('../../assets/Web/home/business/html.png'),
          backgroundColor: '#F08D5A',
          isLine: false,
          content: '提供网站建设、模板建站、网络制作及定制开发',
          i: 4,
          backgroundStyle: { 'background': '#F08D5A' },
          imgStyle: { 'width': '200PX', 'height': '180PX', 'margin-left': '131PX', 'margin-top': '13PX' }
        },
        {
          title: '企业应用管理系统',
          img: require('../../assets/Web/home/business/company.png'),
          backgroundColor: '#379DBA',
          isLine: false,
          content: '开发业务流程系统、SCRM系统、ERP系统、CRM系统等',
          i: 5,
          backgroundStyle: { 'background': '#379DBA' },
          imgStyle: { 'width': '227PX', 'height': '178PX', 'margin-left': '118PX', 'margin-top': '14PX' }
        },
        {
          title: '微信小程序',
          img: require('../../assets/Web/home/business/minipro.png'),
          backgroundColor: '#F4AB8D',
          isLine: false,
          content: '提供微信小程序、支付宝小程序等定制开发',
          i: 6,
          backgroundStyle: { 'background': '#F4AB8D' },
          imgStyle: { 'width': '154PX', 'height': '180PX', 'margin-left': '154PX', 'margin-top': '13PX' }
        },
      ],
      businessContentTitle: '',
      businessGroupTitle: 1,

    }
  },
  watch: {
    navTitle: {
      immediate: true,
      handler(newVal) {
        this.$emit('navTitleChange', newVal)
      },

    }
  },
  methods: {
    // 获取视口高度
    // getHeight() {
    //   this.contentStyleObj.height = window.innerHeight - 79 + 'px'
    //   console.log(this.contentStyleObj.height);
    // },

    // 页面滚动
    // goWheel(event) {
    //   // 向下滚动
    //   // if (event.deltaY > 0) {
    //   //   scrollBy(0, window.innerHeight - 158)
    //   // }
      
    //   if (event.deltaY > 0) {
    //     // let time = setInterval(() => {
    //       // scrollBy(0, window.innerHeight - 158);  // 跳转至
    //       scrollBy({
    //         top: window.innerHeight - 79,
    //         behavior: 'smooth'
    //       })
    //       // clearInterval(time);   //结束滚轴跳转后，关闭定时器
    //     // }, 1)
    //   }
    // },


    goCase(name) {
      this.$emit('navTitleChange', '开发案例')
      this.$router.push({ name: 'Case' })
      this.$emit('casePopNameChange', name)
    },
    sceneTitleChange(title) {
      this.sceneTitle = title
    },
    businessTitleChange(title, groupTitle) {
      this.businessContentTitle = title
      this.businessGroupTitle = groupTitle
    },
    navChange(title) {
      this.navTitle = title
    },
    jobEnter(title) {
      this.jobTitle = title
    },
    messageLeave() {
      messageLeave({
        demandDescribe: this.queryParams.demandDescribe,
        name: this.queryParams.name,
        phone: this.queryParams.phone,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.demandDescribe = ''
          this.queryParams.name = ''
          this.queryParams.phone = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error('提交失败');
        }

      })
    },
    businessTitleClick(title, index) {
      this.businessTitle = title
      this.$refs.carouselRef.setActiveItem(index)
    },
    businessImgChange(imgIndex) {
      this.businessList.forEach((item, index) => {
        if (imgIndex == index) {
          this.businessTitle = item.title
        }
      })
    },
  },
  mounted() {
    // console.log('winh',window.innerHeight);
    this.getHeight = (window.innerHeight - 79) + 'PX'
    const H = window.innerHeight
    if (710 <= H && H < 830) {
      this.getHeight = H * 1.25 - 79 + 'PX'
      // console.log(1);
    } else if (575 <= H && H < 710) {
      this.getHeight = H * 1.5 - 79 + 'PX'
      // console.log(2);
    } else if (450 < H && H < 575) {
      this.getHeight = H * 1.75 - 79 + 'PX'
      // console.log(3);
    } else {
      this.getHeight = H - 79 + 'PX'
      // console.log(4);
    }


    // console.log(typeof(window.innerHeight));
    // console.log(this.getHeight);

    // console.log('---------------------------');
    // 适配不同尺寸屏幕（动态计算根元素的大小fonSize）
    function resizeCount() {
      const baseConstn = 136.6 // 1920的设计稿 / 基数16
      const nowWidth = document.documentElement.clientWidth
      const nowCount =  nowWidth / baseConstn
      document.querySelector('html').style.fontSize = nowCount + 'px'
    }
    // 初始化
    resizeCount()

    window.addEventListener('resize', () => {
        resizeCount()
        // this.getHeight()
    })
  }
}

</script>
<style lang="scss" scoped>
// @media (max-width: 1919PX) and (min-width: 768PX) {
//   html {
//    zoom: 80%;
//   }
//   @-ms-viewport {
//     width: 1920PX; 
//   }
// }

.home {
  .homeTop {
    width: 100%;
    // height: calc(100vh - 79px);
    // height: 100%;
    // height: 100vh;
    .homeBigImg {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    // position: relative;
    // display: flex;
    // align-items: center;
    // overflow: hidden;

    .bg {
      // height: 760PX;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -10;
      // object-fit: cover;
      // display: inline-block;
    }

    .homeTop-right {
      width: 100%;
      min-width: 611PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      // position: absolute;
      // left: 61%;
      // top: -5%;
      position: relative;
      top: 0%;

      .screen {
        // width: 491px;
        width: 785.6px;
        // width: 16.5579rem;
        // height: 342px;
        height: 547.2px;
        // height: 10.9653rem;
        min-width: 491PX;
        min-height: 342PX;
        max-width: 675.874PX;
        max-height: 470.592PX;
        position: relative;
        top: 20PX;
        right: 43PX;
        z-index: 1;
        animation: screen 3s infinite;
      }
      .base {
        // width: 522px;
        width: 835.2px;
        // width: 18.6424rem;
        // height: 206px;
        height: 329.6px;
        // height: 7.392rem;
        min-width: 522PX;
        min-height: 206PX;
        max-width: 718.272PX;
        max-height: 283.456PX;
        position: relative;
        top: -17PX;
        right: 43PX;
      }
      .shade {
        // width: 611px;
        width: 977.6px;
        // width: 19.6424rem;
        // height: 263px;
        height: 420.8px;
        // height: 8.392rem;
        min-width: 611PX;
        min-height: 263PX;
        max-width: 840.736PX;
        max-height: 361.888PX;
        position: absolute;
        bottom: -60PX;
        z-index: -1;
      }
    }

    .text {
      margin-left: 11.27%;
      .qianshu {
        font-weight: bold;
        @media only screen and (max-width: 2560px) {
          font-size: 95PX;    // 当视口宽度为 2560px 甚至更小时
        }
        @media only screen and (max-width: 1366px) {
          font-size: 68PX;    // 当视口宽度为 1366px 甚至更小时
        }
        color: #005ed8;
        font-family: SourceHanSansCN-Medium;
        letter-spacing: 22PX;
      }
      p:nth-child(2) {
        @media only screen and (max-width: 2560px) {
          font-size: 56PX;    // 当视口宽度为 2560px 甚至更小时
          width: 600PX;
        }
        @media only screen and (max-width: 1366px) {
          font-size: 40PX;    // 当视口宽度为 1365px 甚至更小时
        }
        color: #005ed8;
        line-height: 68PX;
        letter-spacing: 19PX;
        font-family: SourceHanSansCN-Medium;
      }
      div {
        width: 452PX;
        height: 5PX;
        @media only screen and (max-width: 2560px) {
          width: 577PX;
          height: 6PX;
        }
        @media only screen and (max-width: 1366px) {
          width: 454PX;
          height: 5PX;
        }
        border-radius: 1PX;
        background-color: rgba($color: #006bf5, $alpha: 0.5);
      }
      p:nth-child(2) {
        margin-top: 21PX;
        margin-bottom: 28PX;
      }
      p:last-child {
        margin-top: 27PX;
        font-family: SourceHanSansCN-Medium;
        letter-spacing: 22PX;
        @media only screen and (max-width: 2560px) {
          font-size: 35PX;    // 当视口宽度为 2560px 甚至更小时
          width: 663PX;
        }
        @media only screen and (max-width: 1366px) {
          font-size: 25PX;    // 当视口宽度为 1365px 甚至更小时
          letter-spacing: 20PX;
        }
        color: #005ed8;
      }
    }
  }


  .homeScene {
    margin-top: 85px;
    // padding-bottom: 30PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .homeScene-top {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 442PX;
        height: 69PX;
      }
    }
    .homeScene-bottom {
      // padding-left: 128PX;
      // padding-right: 128PX;
      margin-top: 71PX;
      margin-bottom: 20PX;
      .homeScene-bottom-list:nth-child(1) {
        border-bottom: none;
      }

      .homeScene-bottom-list {
        display: flex;
        // justify-content: space-between;
        background-color: #fbfdff;
        border: 1PX solid #e5e5e5;
        overflow: hidden;
        .noCheck,
        .check {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .check {
          width: 100%;
          height: 100%;
          animation: toBottom 0.5s ease-in-out;
          // transition: height 2s;
          background-color: #006bf5;

          p {
            color: #fff !important;
          }
          .homeScene-bottom-list-item-line {
            background-color: #fff !important;
          }
        }

        .homeScene-bottom-list-item {
          width: 296PX;
          height: 320PX;
          border-right: 1PX solid #e5e5e5;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: 68PX;
            height: 68PX;
          }
          .homeScene-bottom-list-item-line {
            width: 43PX;
            height: 1PX;
            background-color: #c6c6c6;
            margin-top: 42PX;
            margin-bottom: 20PX;
          }
          p {
            font-family: SourceHanSansCN-Medium;
            font-size: 24PX;
            color: #000000;
          }
          .homeScene-bottom-list-item-button {
            position: absolute;
            bottom: 20PX;
            background-color: #006bf5;
            border: 1PX solid #ffffff;
            border-radius: 6PX;
            width: 180PX;
            height: 40PX;
            font-size: 20PX;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .homeScene-bottom-list-item:last-child {
          border: none;
        }
      }
    }
  }
  .homeBusiness {
    margin-top: 64PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .homeBusiness-top {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 442PX;
        height: 69PX;
      }
    }
    .homeBusiness-bottom {
      margin-top: 71PX;
      display: flex;

      .homeBussiness-bottom-list {
        display: flex;
        overflow: hidden;

        .check {
          animation: toBottom 0.5s ease-in-out;
          .homeBusiness-bottom-check-top {
            height: 209PX;
          }

          .homeBusiness-bottom-check-animation {
            animation: toBusinessBottom 0.5s ease-in-out;
          }

          .homeBusiness-top-check-animation {
            animation: toBusinessTop 0.5s ease-in-out;
          }

          .homeBusiness-bottom-check-bottom {
            height: 202PX;

            .homeBusiness-bottom-check-bottom-title {
              font-size: 26PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 86PX;
              margin-left: 21PX;
              padding-top: 28PX;
            }

            .homeBusiness-bottom-check-bottom-line {
              width: 313PX;
              height: 1PX;
              background: #ffffff;
              opacity: 0.6;
              border-radius: 1PX;
              margin: 0 auto;
            }

            .homeBusiness-bottom-check-bottom-content {
              width: 288PX;
              height: 41PX;
              font-size: 16PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 24PX;
              margin-left: 21PX;
              margin-top: 41PX;
            }
          }
        }

        .check1 {
          animation: toBottom 0.5s ease-in-out;
          .homeBusiness-bottom-check-top {
            height: 209PX;
          }

          .homeBusiness-bottom-check-animation {
            animation: toBusinessBottom 0.5s ease-in-out;
          }

          .homeBusiness-top-check-animation {
            animation: toBusinessTop 0.5s ease-in-out;
          }

          .homeBusiness-bottom-check-bottom {
            height: 202PX;

            .homeBusiness-bottom-check-bottom-title {
              font-size: 26PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 86PX;
              margin-left: 21PX;
              padding-top: 28PX;
            }

            .homeBusiness-bottom-check-bottom-line {
              width: 419PX;
              height: 1PX;
              background: #ffffff;
              opacity: 0.6;
              border-radius: 1PX;
              margin: 0 auto;
            }

            .homeBusiness-bottom-check-bottom-content {
              height: 41PX;
              font-size: 16PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 24PX;
              margin-left: 21PX;
              margin-top: 41PX;
            }
          }
        }

        .homeBussiness-bottom-list-item1 {
          width: 357PX;

          .noCheck {
            .homeBusiness-bottom-tab1-top {
              height: 209PX;
              background: #6c81da;
              img {
                width: 178PX;
                height: 180PX;
                margin-left: 90PX;
                margin-top: 12PX;
              }
            }

            .homeBusiness-bottom-tab1-bottom {
              height: 202PX;
              background: #f4f4f4;

              .homeBusiness-bottom-tab1-bottom-title {
                font-size: 26PX;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #989898;
                line-height: 86PX;
                margin-left: 21PX;
                padding-top: 28PX;
              }

              .homeBusiness-bottom-tab1-bottom-line {
                width: 313PX;
                height: 1PX;
                background: #dfdfdf;
                opacity: 0.6;
                border-radius: 1PX;
                margin: 0 auto;
              }

              .homeBusiness-bottom-tab1-bottom-content {
                width: 288PX;
                height: 41PX;
                font-size: 16PX;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 24PX;
                margin-left: 21PX;
                margin-top: 41PX;
              }
            }
          }
        }

        .homeBussiness-bottom-list-item2 {
          width: 357PX;
          margin-left: 6PX;

          .noCheck {
            .homeBusiness-bottom-tab1-top {
              height: 201PX;
              background: #f4f4f4;
            }

            .homeBusiness-bottom-tab1-bottom {
              height: 202PX;
              background: #f4f4f4;
            }

            .homeBusiness-bottom-tab1-title {
              font-size: 26PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #989898;
              line-height: 86PX;
              margin-left: 21PX;
              margin-top: 27PX;
            }

            .homeBusiness-bottom-tab1-line {
              width: 313PX;
              height: 1PX;
              background: #dfdfdf;
              border-radius: 1PX;
              margin: 0 auto;
            }

            .homeBusiness-bottom-tab1-content {
              font-size: 16PX;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              line-height: 24PX;
              margin-left: 22PX;
              margin-top: 41PX;
            }
          }
        }

        .homeBussiness-bottom-list-item3 {
          width: 461PX;
          margin-left: 8PX;

          .homeBusiness-bottom-tab3-top {
            height: 131PX;
            background: #f4f4f4;
          }

          .homeBusiness-bottom-tab3-middle {
            height: 131PX;
            background: #f4f4f4;
            margin-top: 7PX;
          }

          .homeBusiness-bottom-tab3-bottom {
            height: 131PX;
            background: #f4f4f4;
            margin-top: 10PX;
          }

          .homeBusiness-bottom-tab3-title {
            font-size: 26PX;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #989898;
            line-height: 86PX;
            margin-left: 22PX;
            margin-top: 27PX;
          }

          .homeBusiness-bottom-tab3-content {
            font-size: 16PX;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 24PX;
            margin-left: 22PX;
            margin-top: -6PX;
          }
        }
      }
    }
  }
  .homeService {
    margin-top: 85PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-left: 136PX;
    // padding-right: 136PX;
    .homeService-top {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 70PX;
      img {
        width: 442PX;
        height: 70PX;
      }
    }
    .homeService-bottom {
      display: flex;
      .homeService-bottom-list {
        display: flex;
        flex-direction: column;
        width: 279PX;
        height: 257PX;
        // justify-content: space-between;
        margin-right: 18PX;
        position: relative;
        padding-left: 30PX;
        box-sizing: border-box;
        img {
          width: 279PX;
          height: 257PX;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
        p:nth-child(2) {
          color: #000000;
          font-size: 28.47PX;
          margin-top: 67PX;
          font-weight: bold;
        }
        p {
          color: #ababab;
          font-size: 16PX;
          margin-top: 59PX;
          letter-spacing: 2PX;
        }
        p:last-child {
          margin-top: 4PX;
        }
      }
      .homeService-bottom-list:last-child {
        margin-right: 0;
      }
    }
  }
  .homeJob {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 83PX;
    .homeJob-top {
      margin-bottom: 70PX;
      img {
        width: 442PX;
        height: 70PX;
        z-index: 2;
      }
    }
    .homeJob-bottom {
      display: flex;
      justify-content: center;
      .homeJob-bottom-list {
        display: flex;
        .homeJob-bottom-list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f3;
          width: 284PX;
          height: 342PX;
          margin-top: 22PX;
          img {
            margin-top: 29PX;
            margin-bottom: 39PX;
          }
          p {
            color: #999999;
            font-size: 12PX;
            letter-spacing: 1PX;
            line-height: 15.6PX;
          }
          p:nth-child(1),
          p:nth-child(2) {
            line-height: 16PX;
            letter-spacing: 1PX;
          }
          p:nth-child(4) {
            color: #333333;
            font-size: 16PX;
            margin-bottom: 16PX;
            letter-spacing: 1PX;
          }
        }
        .homeJob-bottom-list-itemCheck {
          position: relative;
          z-index: 2;
          margin-left: -10PX;
          margin-right: -10PX;
          box-shadow: 0PX 3PX 14PX 2PX rgba(163, 163, 163, 0.08);
          background-color: #fff;
          border-radius: 10PX;
          // width: 590PX;
          padding-left: 42PX;
          padding-right: 42PX;
          // height: 510PX;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          animation: right 0.3s ease;
          // animation: right1 1s linear;
          // transform: scale(1.1);

          .homeJob-bottom-list-itemCheck-top {
            width: 337PX;
            height: 129PX;
            position: relative;
            animation: right 0.3s ease;

            .jobBg1 {
              position: absolute;
              bottom: 2PX;
              left: 0;
              z-index: -1;
            }
            div {
              position: absolute;
              top: 36PX;
              right: 49PX;
              text-align: center;
            }
            p {
              color: #999999;
              font-size: 12PX;
              letter-spacing: 1PX;
              line-height: 18.5PX;
            }
          }
          .homeJob-bottom-list-itemCheck-center {
            margin-top: 41PX;
            animation: right 0.3s ease;

            p {
              color: #999999;
              font-size: 16PX;
              letter-spacing: 2PX;
              line-height: 22PX;
            }
            p:first-child {
              color: #333333;
              font-size: 26PX;
              letter-spacing: 2PX;
              line-height: 51PX;
              font-family: SourceHanSansCN-Medium;
            }
          }
          .homeJob-bottom-list-itemCheck-more {
            animation: right 0.3s ease;

            width: 100PX;
            height: 30PX;
            border-radius: 14.89PX;
            background: linear-gradient(to right, #0066ff, #2591ff);
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-start;
            margin-top: 20PX;
            p {
              font-family: SourceHanSansCN-Medium;
              color: #ffffff;
              font-size: 12PX;
              letter-spacing: 0.5PX;
            }
            img {
              width: 13PX;
              height: 11PX;
              margin-left: 5PX;
            }
          }
        }
      }
    }
  }

  .homeWhy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 83PX;

    .homeWhy-top {
      margin-bottom: 70PX;
      img {
        width: 442PX;
        height: 70PX;
        z-index: 2;
      }
    }
    .homeWhy-center {
      position: relative;
      img {
        width: 1248PX;
        height: 480PX;
      }
      .content {
        position: absolute;
        top: 129PX;
        right: 100PX;
        .homeWhy-center-title {
          font-size: 29PX;
          color: #fff;
          font-family: SourceHanSansCN-Bold;
        }

        .homeWhy-center-title:nth-child(2) {
          margin-bottom: 39PX;
        }

        p {
          font-size: 15PX;
          color: #fff;
          font-family: SourceHanSansCN-Regular;
          margin-bottom: 15PX;
        }
      }
    }
  }
}

@keyframes right {
  0% {
    transform: translate(30PX);
  }
  100% {
    transform: translate(0PX);
  }
}

@keyframes toBottom {
  0% {
    transform: translateY(-319PX);
  }
  100% {
    transform: translateY(0PX);
  }
}
// @keyframes right1 {
//   from {
//     width: 400PX;
//   }
//   to {
//     width: 510PX;
//   }
// }

@keyframes screen {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(0) translateY(-5%);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
</style>
